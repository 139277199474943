import { useState } from 'react';
import { injectIntl } from 'react-intl';
import { Modal } from 'antd';

import { requestPOS } from 'services/shipments';

import BRButton from 'components/BRButton/BRButton';
import { notify } from 'components/Notify/Notify';

import POSImage from 'assets/images/pos-machine.png';

import './PosRequestedModal.less';

const PosRequestedModal = ({
  intl,
  apiKey,
  trackingNumber,
  close,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleSendPOSRequest = async () => {
    setIsLoading(true);
    try {
      await requestPOS({
        token: apiKey,
        payload: {
          trackingNumber
        }
      });

      notify({
        msg: intl.formatMessage({
          id: 'tracking.request_pos.request_sent'
        }),
        type: 'success'
      });
    } catch (error) {
      notify({ msg: error.message, type: 'error' });
    }
    setIsLoading(false);
    close();
  };

  return (
    <Modal
      title={
        <span className="display-xs">
          {intl.formatMessage({
            id: 'tracking.request_pos.modal_header'
          })}
        </span>
      }
      onCancel={close}
      width={null}
      wrapClassName="br-deliveries__pos-modal-wrapper"
      footer={
        <>
          <BRButton
            label={intl.formatMessage({
              id: 'common.cancel'
            })}
            type="outline"
            onClick={() => {
              close();
            }}
          />
          <BRButton
            label={intl.formatMessage({
              id: 'tracking.request_pos.send'
            })}
            type="primary"
            onClick={handleSendPOSRequest}
            loading={isLoading}
          />
        </>
      }
      {...props}
    >
      <div className="br-deliveries__pos-requested-modal">
        <img src={POSImage} alt="Pos Machine" />
        <p className="display-sm">
          {intl.formatMessage({
            id: 'tracking.request_pos.modal_title'
          })}
        </p>
        <p className="body br-deliveries__pos-modal-subtitle">
          {intl.formatMessage({
            id: 'tracking.request_pos.modal_subtitle'
          })}
        </p>
      </div>
    </Modal>
  );
};

export default injectIntl(PosRequestedModal);
