import { fmt } from 'components/IntlWrapper/IntlWrapper';

import { ReactComponent as InstaPay } from 'assets/icons/InstaPayLogo.svg';
import { ReactComponent as Visa } from 'assets/icons/visaLogo.svg';
import { ReactComponent as Mastercard } from 'assets/icons/mastercardLogo.svg';

export const ONLINE_PAYMENT_METHODS = {
  CARD: {
    value: 'card',
    label: (
      <span className="body-medium">
        {fmt(
          { id: 'online_payments.card' },
          {
            span: (children) => (
              <span className="br-online-payment__card-option-subtitle">
                {children}
              </span>
            )
          }
        )}
      </span>
    ),
    icon: (
      <span className="br-payment__cards-icons-option">
        <Visa />
        <Mastercard />
      </span>
    )
  },
  // INSTAPAY:{
  //   value: 'instaPay',
  //   label: fmt({ id: 'online_payments.instapay' }),
  //   icon: <InstaPay />
  // },
  OTHERS: {
    value: 'others',
    label: fmt({ id: 'online_payments.other_methods' }),
    icon: (
      <span className="br-coming-soon-tag caption">
        {fmt({ id: 'online_payments.coming_soon' })}
      </span>
    ),
    disabled: true
  }
};
