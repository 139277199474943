import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { openModal } from 'utils/modal';

import BRButton from 'components/BRButton/BRButton';
import PayModal from 'components/ShipmentTracking/components/OnlinePayment/PayModal/PayModal';

import { ReactComponent as PayOnline } from 'assets/icons/payOnlineIcon.svg';

const OnlinePaymentBtn = ({ trackingDetails, codAmount, verify, token }) => {
  const { formatMessage } = useIntl();
  const [open, setOpen] = useState(false);

  const openPayModal = async () => {
    openModal(PayModal, { trackingDetails, codAmount, token, setOpen });
  };

  const handleOpenPayModal = async () => {
    setOpen(true);
    if (!token) {
      await verify();
    } else {
      openPayModal();
    }
  };

  useEffect(() => {
    if (token && codAmount && open) {
      openPayModal();
    }
  }, [token, codAmount]);

  return (
    <BRButton
      prefixIcon={<PayOnline />}
      type="primary"
      className="button-md"
      label={formatMessage({
        id: `tracking.instapay.btn_label`
      })}
      onClick={handleOpenPayModal}
    />
  );
};

export default OnlinePaymentBtn;
